import React, { Component } from 'react';
import config from '../config';
import { Redirect } from 'react-router-dom';

class Step1 extends Component {
    constructor(props) {
        super(props);
        
        this.state = {
            nextStep: false
        }
    }

    nextStep = (e) => {
        this.setState({nextStep: true});
    }

    render() {
        if (this.state.nextStep)
            return (<Redirect push to='/step2' />);

        return (
            <div>
                {
                    (config.environment.authorizeNet.keyCode === "prod") ? null : 
                        <h1 style={{color: "red"}}>{config.environment.authorizeNet.keyCode} {config.environment.authorizeNet.keyCode} {config.environment.authorizeNet.keyCode} {config.environment.authorizeNet.keyCode}</h1>
                }

                <p>
                <strong>The Latin Recording Academy is pleased to offer customized participation certificates</strong> which recognize anyone who was creatively or professionally involved in a Latin GRAMMY-winning or Latin GRAMMY-nominated recording.
                </p>

                <p><strong>Who is eligible?</strong></p>
                <p>Musicians, composers, publishers, studios, and labels may apply for a participation certificate with proof of participation in a Latin GRAMMY-winning or Latin GRAMMY-nominated recording.</p>

                <p>Participants on Latin GRAMMY-winning or Latin GRAMMY-nominated recordings from past years can also order new or replacement certificates for any previous orders. Certificates are 8.5" x 11" with customized information. Certificates are printed on heavy, ivory stock, embossed with the official Latin Recording Academy shield. Certificates are shipped unframed with a protective folder.</p>

                <p><em><u>Please note that all requests are pending on approval. Your money will be refunded if we deny your application.</u></em></p>
                
                <p>For each certificate:</p>

                <p>Complete the Special Certificate Order Form:</p>
                <ul>
                    <li>Include the name, recording, awards year, and role on the recording.</li>
                    <li>Provide a physical mailing address (not a PO Box) and contact information for delivery.</li>
                </ul>

                <p>Upload Proof of Participation: Proof is required for each requested certificate.</p>

                 <ul>
                    <li><strong>A certificate will not be issued without proof or evidence of the participant’s involvement.</strong> Examples include a copy of the liner notes and credits.</li>
                    <li>We will compare your uploaded credits to the official credits submitted by the label during our entry process.</li>
                </ul>

                <p>No emails, text messages, or other non-official forms of documentation.</p>  

                <p>Provide payment:</p>

                <ul>
                    <li>Certificates are US ${config.environment.application.certificatePrice}.00 each, including handling fees.</li>
                    <li>Any major credit card can be used as payment for online orders.</li>
                    <li>Ordering online is recommended as the fastest and most secure way to order certificates.</li>
                </ul>

                <p><input type="button" onClick={this.nextStep} className="btn btn-primary form-control" value="Purchase Participation Certificates" /></p>

            </div>
        );
    }
}

export default Step1;