import React, { Component } from 'react';
import config from '../config';
import { Redirect } from 'react-router-dom';

class Step1 extends Component {
    constructor(props) {
        super(props);
        
        this.state = {
            nextStep: false
        }
    }

    nextStep = (e) => {
        this.setState({nextStep: true});
    }

    render() {
        if (this.state.nextStep)
            return (<Redirect push to='/step2' />);

        return (
            <div>
                {
                    (config.environment.authorizeNet.keyCode === "prod") ? null : 
                        <h1 style={{color: "red"}}>{config.environment.authorizeNet.keyCode} {config.environment.authorizeNet.keyCode} {config.environment.authorizeNet.keyCode} {config.environment.authorizeNet.keyCode}</h1>
                }

                <p>
                The Recording Academy is pleased to offer customized certificates which recognize those who participated in or contributed to a GRAMMY-winning or GRAMMY-nominated recording.
                </p>

                <p><strong>Important: Please Read</strong><br />
                    The Participation Certificate orders system is open from <strong>March 8</strong> to <strong>June 15, 2023</strong>.</p>
                <p><em>If you are a GRAMMY Nominee, you will receive a gratis Nominee Certificate and do not need to order a Participation Certificate.</em></p>


                <p><strong>Who is eligible?</strong><br />
                Musicians, composers, publishers, studios, and labels may apply for a participation certificate with proof of participation in a GRAMMY-winning or GRAMMY-nominated recording.</p>

                <p><em><u>Please note that all requests are pending on approval. Your money will be refunded if we deny your application.</u></em></p>
                
                <p><strong>What do I get?</strong><br />
                Certificates are 8.5" x 11" with customized information. Certificates are printed on heavy, ivory stock, embossed with the official GRAMMY <strong>logo</strong> in gold & black foil. Certificates are shipped unframed.</p>

                <p><strong>What Documents do I need to order?</strong></p>
                <ul>
                    <li>Official product liner notes or professional service crediting you with the role. Upload PDF, scan, or clear photo. <u>Pertinent information like album or song title and artist name needs to appear on the file uploaded</u>.</li>
                    <li><strong>No</strong> emails, text messages, or other <strong>non-official</strong> forms of documentation.</li>
                </ul>

               <p> <strong>Cost</strong><br />
               Certificates are ${config.environment.application.certificatePrice}.00 each (Includes tax and shipping)<br />
               <strong>We accept major credit cards.</strong></p>

                <p>Please allow <strong>6-10 weeks</strong> for delivery.</p>

                <p>For questions: <a href="mailto:ParticipationCertificate@recordingacademy.com">ParticipationCertificate@recordingacademy.com</a></p>

                <p>&nbsp;</p>

                <p><input type="button" onClick={this.nextStep} className="btn btn-primary form-control" value="Purchase Participation Certificates" /></p>

            </div>
        );
    }
}

export default Step1;