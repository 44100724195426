import React, { Component } from 'react';
import { Redirect } from 'react-router-dom';
import rolesGrammyJson from '../data/roles_grammy.json';
import rolesLatinGrammyJson from '../data/roles_latingrammy.json';
import config from '../config';
import ProcessingOverlay from './ProcessingOverlay';

class Step3 extends Component {
    constructor(props) {
        super(props);

        this.state = {
            id: this.props.parentState.certificate.id, 
            name: this.props.parentState.certificate.name,
            email: this.props.parentState.certificate.email,
            phone: this.props.parentState.certificate.phone,
            address1: this.props.parentState.certificate.address1,
            address2: this.props.parentState.certificate.address2,
            address3: this.props.parentState.certificate.address3,
            addressUnitNumber: this.props.parentState.certificate.addressUnitNumber,
            city: this.props.parentState.certificate.city,
            stateProvinceID: this.props.parentState.certificate.stateProvinceID,
            postalCode: this.props.parentState.certificate.postalCode,
            countryID: this.props.parentState.certificate.countryID,
            numberOfCertificates: this.props.parentState.certificate.numberOfCertificates,
            totalCost: this.props.parentState.certificate.totalCost,
            participationDescription: this.props.parentState.certificate.participationDescription,
            participationNotes: this.props.parentState.certificate.participationNotes,
            certificateHash: this.props.parentState.certificate.certificateHash,
            selectedFile: this.props.parentState.certificate.selectedFile,
            selectedFileError: "",
            nameOnCertificate: this.props.parentState.certificate.nameOnCertificate,
            certificateRoles: [],
            isProcessing: false,
            previousStep: false,
            payByCreditCard: false,
            payByCheck: false,

            countryArray: [],
            stateArray: [],
            academyID: config.environment.application.academyID
        }

        this.handleChange = this.handleChange.bind(this);

    }
    
    updateParentState() {
        this.props.parentState.certificate.id = this.state.id;
        this.props.parentState.certificate.name = this.state.name;
        this.props.parentState.certificate.email = this.state.email;
        this.props.parentState.certificate.phone = this.state.phone;
        this.props.parentState.certificate.address1 = this.state.address1;
        this.props.parentState.certificate.address2 = this.state.address2;
        this.props.parentState.certificate.address3 = this.state.address3;
        this.props.parentState.certificate.addressUnitNumber = this.state.addressUnitNumber;
        this.props.parentState.certificate.city = this.state.city;
        this.props.parentState.certificate.stateProvinceID = this.state.stateProvinceID;
        this.props.parentState.certificate.postalCode = this.state.postalCode;
        this.props.parentState.certificate.countryID = this.state.countryID;
        this.props.parentState.certificate.numberOfCertificates = this.state.numberOfCertificates;
        this.props.parentState.certificate.totalCost = this.state.totalCost;
        this.props.parentState.certificate.participationDescription = this.state.participationDescription;
        this.props.parentState.certificate.participationNotes = this.state.participationNotes;
        this.props.parentState.certificate.selectedFile = this.state.selectedFile;
        this.props.parentState.certificate.certificateHash = this.state.certificateHash;
        this.props.parentState.certificate.nameOnCertificate = this.state.nameOnCertificate;
        this.props.parentState.certificate.academyID = this.state.academyID;
    }

    async componentDidMount() {
        let roles_json = "";
        if (config.environment.application.academyID === 1) {
            roles_json = rolesGrammyJson.map(current_role => { return {value: current_role.name, display: current_role.name} });
        }
        if (config.environment.application.academyID === 2) {
            roles_json = rolesLatinGrammyJson.map(current_role => { return {value: current_role.name, display: current_role.name} });
        }
        this.setState({ certificateRoles: [{value: '', display: 'Please select your role...'}].concat(roles_json) });

        let country_data = await import('../data/countries.json');
        this.setState({countryArray: country_data.default.map(current_object => { return current_object })});

        let state_data = await import('../data/states.json');
        this.setState({stateArray: state_data.default.map(current_object => { return current_object })});

    }

    handleChange(event) {
        this.setState({ [event.target.name]: event.target.value });

        switch (event.target.name) {
            case "numberOfCertificates":
                this.setState({totalCost: event.target.value * config.environment.application.certificatePrice});
                break;
            case "countryID":
                this.setState({stateProvinceID: ""});
                break;
            default:
                break;
        }
    }
   
    isFieldValid(field_name) {
        var is_valid = false;

        switch (field_name) {
            case "name":
                is_valid = (this.state.name !== "");
                break;
            case "nameOnCertificate":
                is_valid = (this.state.nameOnCertificate !== "");
                break;
            case "email":
                //https://www.regular-expressions.info/email.html
                is_valid = (this.state.email !== "" && (/[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?/.test(this.state.email)));
                break;
            case "phone":
                is_valid = (this.state.phone !== "");
                break;
            case "address1":
                is_valid = (this.state.address1 !== "");
                break;
            case "city":
                is_valid = (this.state.city !== "");
                break;
            case "stateProvince":
                is_valid = 
                    (this.state.stateProvinceID !== undefined && this.state.stateProvinceID !== "") || 
                    (
                        (this.state.countryID !== undefined && this.state.countryID !== "") &&
                        (this.statesForCountry().length === 0)
                    )
                     
                ; 
                break;
            case "postalCode":
                is_valid = (this.state.postalCode !== "");
                break;
            case "country":
                is_valid = (this.state.countryID !== undefined && this.state.countryID !== "");
                break;
            case "numberOfCertificates":
                is_valid = (this.state.numberOfCertificates > 0);
                break;
            case "participationDescription":
                is_valid = (this.state.participationDescription !== "");
                break;
            case "selectedFile":
                is_valid = (this.state.selectedFile !== null);
                break;
            default:
                console.log(field_name + " was not found");
        }

        return is_valid;
    }

    isFormValid() {
        var is_form_valid = (
            this.isFieldValid("name") && 
            this.isFieldValid("nameOnCertificate") && 
            this.isFieldValid("email") &&
            this.isFieldValid("phone") &&
            this.isFieldValid("address1") &&
            this.isFieldValid("city") &&
            this.isFieldValid("stateProvince") &&
            this.isFieldValid("postalCode") &&
            this.isFieldValid("country") &&
            this.isFieldValid("numberOfCertificates") && 
            this.isFieldValid("participationDescription") &&
            this.isFieldValid("selectedFile")
        );

        return is_form_valid;
    }

    statesForCountry() {
        let state_array = [];
        var country_id = parseInt(this.state.countryID);

        if (this.state.stateArray.length > 0) {
            for (var x = 0; x < this.state.stateArray.length; x++) {
                var temp_state = this.state.stateArray[x];

                if (temp_state.countryID === country_id)
                    state_array.push(temp_state);
            }
        }

        return state_array;
    }

    chooseFile = (e) => {
        window.document.getElementById("choose-file").click();
    }

    fileUploadHandler = (event) => {
        let selected_file = event.target.files[0];
        this.setState({ selectedFileError: "" });

        if (selected_file === undefined) {
            //They clicked cancel.  Let's do nothing here.
        }
        else if (selected_file.size > config.MAX_ATTACHMENT_SIZE)
        {
            //File too large!
            event.target.value = "";
            this.setState({ selectedFile: null });
            this.setState({ selectedFileError: "The maximum file size is 25MB" });
        }
        else if (!/(\.pdf|\.doc|\.docx|\.jpg|\.gif|\.png)$/i.test(selected_file.name)) {
            //Bad file extension.
            event.target.value = "";
            this.setState({ selectedFile: null });
            this.setState({ selectedFileError: "Valid file types are: PDF, DOC, DOCX, JPG, GIF, PNG" });
		}
        else {
            //It's a good size and file extension.
            this.setState({ selectedFile: selected_file });
        }
    }

    previousStep = (e) => {
        e.preventDefault();
        this.updateParentState();
        this.setState({previousStep: true});
    }

    makePaymentByCheck = (e) => {
        e.preventDefault();
        this.updateParentState();
        
        this.saveCertificateToDatabase().then((response) => {
            if (response) {
                this.props.parentState.paymentMethod = "check";
                this.setState({payByCheck: true});
            }
        });
    }

    makePaymentByCreditCard = (e) => {
        e.preventDefault();
        this.updateParentState();
        
        this.saveCertificateToDatabase().then((response) => {
            if (response) {
                this.props.parentState.paymentMethod = "credit_card";
                this.setState({payByCreditCard: true});
            }
        });
    }

    async saveCertificateToDatabase() {
        this.setState({isProcessing: true});
        let return_value = true;
        let url = config.environment.application.baseUrl + "/api/certificate/";

        await fetch(url, {
            method: 'POST',
            headers: new Headers({
              'Accept': 'application/json',
              'Content-Type': 'application/json',
            }),
            body: JSON.stringify(this.props.parentState.certificate)
        })
        .then((response) => {
            return response.json();
        })
        .then((data) => {
            this.setState({
                id: data.certificateID,
                certificateHash: data.certificateHash
            });
        })
        .then((data) => {
            this.props.parentState.certificate.id = this.state.id;
            this.props.parentState.certificate.certificateHash = this.state.certificateHash;
        })
        .catch((error) => console.log(error));

        if (!(this.state.id > 0))
            return_value = false;

        //Now, upload the supporting file
        const data = new FormData()
        data.append('file', this.state.selectedFile);
        data.append('certificateHash', this.state.certificateHash);

        if (return_value) {
            //Modify the URL to upload the file.
            url += this.state.id + "/uploadFile";

            await fetch(url, {
                method: 'POST',
                body: data
            })
            .then((response) => {
                return response.json();
            })
            .then((response) => {
                if (response !== "")
                    return_value = false;
            })
            .catch((error) => console.log(error));
        }

        this.setState({isProcessing: false});

        return return_value;
    }

    render() {

        // Catch previous orders
        if (this.props.parentState.transactionID && this.props.parentState.transactionID !== "") {
            this.props.parentState.transactionID = "";
            this.props.parentState.certificate.id = 0;
            this.props.parentState.certificate.numberOfCertificates = 0;
            this.props.parentState.certificate.totalCost = 0;
            this.props.parentState.certificate.participationDescription ="";
            this.props.parentState.certificate.participationNotes = "";
            this.props.parentState.certificate.selectedFile = null;
            return (<Redirect push to='/step2'/>);
        }

        if (this.state.previousStep || this.props.parentState.selectedHistoryItem === "")
            return (<Redirect push to='/step2' />);

        if (this.state.payByCheck)
            return (<Redirect push to='/thankyou' />);

        if (this.state.payByCreditCard) 
            return (<Redirect push to='/payment' />);

        return (
            <div>
                <ProcessingOverlay isProcessing={this.state.isProcessing} />  
                <div className="row">
                    <div className="col-xs-12 col-sm-12 col-md-12">
                        <h1>Contact Information</h1>
                    </div>
                    <div className="col-xs-12 col-sm-12 col-md-4">
                        <b>Name</b><br />
                        <input type="text" className={"form-control " + (!this.isFieldValid("name") ? "validationError" : "")} name="name" value={this.state.name} onChange={this.handleChange} />
                    </div>
                    <div className="col-xs-12 col-sm-6 col-md-4">
                        <b>Email</b><br />
                        <input type="text" className={"form-control " + (!this.isFieldValid("email") ? "validationError" : "")} name="email" value={this.state.email} onChange={this.handleChange} />
                    </div>
                    <div className="col-xs-12 col-sm-6 col-md-4">
                        <b>Phone</b><br />
                        <input type="text" className={"form-control " + (!this.isFieldValid("phone") ? "validationError" : "")} name="phone" value={this.state.phone} onChange={this.handleChange} />
                    </div>
                    <div className="col-xs-12 col-sm-12 col-md-9">
                        <b>Address 1 (No PO Boxes)</b><br />
                        <input type="text" className={"form-control " + (!this.isFieldValid("address1") ? "validationError" : "")} name="address1" value={this.state.address1} onChange={this.handleChange}  />
                    </div>
                    <div className="col-xs-12 col-sm-12 col-md-3">
                        <b>Unit/Suite #</b><br />
                        <input type="text" className={"form-control"} name="addressUnitNumber" value={this.state.addressUnitNumber} onChange={this.handleChange}  />
                    </div>
                    <div className="col-xs-12 col-sm-12">
                        <b>Address 2</b><br />
                        <input type="text" className="form-control" name="address2" value={this.state.address2} onChange={this.handleChange}  />
                    </div>
                    <div className="col-xs-12 col-sm-12">
                        <b>Address 3</b><br />
                        <input type="text" className="form-control" name="address3" value={this.state.address3} onChange={this.handleChange}  />
                    </div>
                    <div className="col-xs-12 col-sm-12">
                        <b>Country</b><br />
                        <select name="countryID" value={this.state.countryID} onChange={this.handleChange} className={"form-control " + (!this.isFieldValid("country") ? "validationError" : "")}>
                            <option value="">Select Country...</option>
                            {
                                this.state.countryArray.map((current_object) => {
                                    return <option key={`country_${current_object.id}`} value={current_object.id}>{current_object.name}</option>
                                })
                            }
                        </select>
                    </div>
                    <div className="col-xs-12 col-sm-12 col-md-5">
                        <b>City</b><br />
                        <input type="text" className={"form-control " + (!this.isFieldValid("city") ? "validationError" : "")} name="city" value={this.state.city} onChange={this.handleChange}  />
                    </div>
                    <div className="col-xs-12 col-sm-8 col-md-4">
                        <b>State/Province</b><br />
                        <select name="stateProvinceID" value={this.state.stateProvinceID} onChange={this.handleChange} className={"form-control " + (!this.isFieldValid("stateProvince") ? "validationError" : "")}>
                            <option value="">Select State/Province...</option>
                            {
                                this.statesForCountry().map((current_object) => {
                                    return <option key={`state_${current_object.id}`} value={current_object.id}>{current_object.name}</option>
                                })
                            }
                        </select>
                    </div>
                    <div className="col-xs-12 col-sm-4 col-md-3">
                        <b>Postal Code</b><br />
                        <input type="text" className={"form-control " + (!this.isFieldValid("postalCode") ? "validationError" : "")} name="postalCode" value={this.state.postalCode} onChange={this.handleChange}  />
                    </div>
                    <div className="col-xs-12 col-sm-12 col-md-12">
                        <br />
                        <br />
                    </div>
                    <div className="col-xs-12 col-sm-12 col-md-12">
                        <h1>Certificate Information</h1>
                    </div>
                    <div className="col-xs-12 col-sm-12 col-md-4">
                        <b>Name (as it will appear on certificate)</b><br />
                        <input type="text" className={"form-control " + (!this.isFieldValid("nameOnCertificate") ? "validationError" : "")} name="nameOnCertificate" value={this.state.nameOnCertificate} onChange={this.handleChange} />
                    </div>
                    <div className="col-xs-12 col-sm-12">
                        <b>Please select role on this project</b> (the Recording Academy reserves the right to determine whether or not it is an eligible participation)
                        <select name="participationDescription" value={this.state.participationDescription} onChange={this.handleChange} className={"form-control " + (this.state.participationDescription === "" ? "validationError" : "")}>
                            {this.state.certificateRoles.map((certificate_role) => <option key={certificate_role.value} value={certificate_role.value}>{certificate_role.display}</option>)}
                        </select>
                    </div>
                    <div className="col-xs-12 col-sm-12 col-md-12">
                        <b>Notes to support your role - optional (500 character limit)</b><br />
                        <textarea className={"form-control"} name="participationNotes" value={this.state.participationNotes} onChange={this.handleChange} maxLength="500" />
                    </div>
                    <div className="col-xs-12 col-sm-12">
                        <br />
                        <b>Upload Liner Notes</b><br />
                        <input style={{display: "none"}} id="choose-file" type="file" onChange={this.fileUploadHandler} />
                        <input type="button" className={"btn " + (this.state.selectedFile === null ? "btn-danger" : "btn-secondary")} value="Browse for Files" onClick={this.chooseFile} />&nbsp;&nbsp;
                        <span><b>Selected file: </b>{(this.state.selectedFile === null) ? "" : this.state.selectedFile.name}</span><span style={{color: "red"}}>{this.state.selectedFileError}</span><br />
                        <br />
                        Valid file types are: PDF, DOC, DOCX, JPG, GIF, PNG<br />
                        Maximum file size is 25MB.<br />
                        <br />
                    </div>
                    <div className="col-xs-12 col-sm-12">
                        <b>Number of Copies (${config.environment.application.certificatePrice}/each):</b><br />
                        <input type="number" min="0" className={"form-control " + (!this.isFieldValid("numberOfCertificates") ? "validationError" : "")} style={{width: "100px"}} name="numberOfCertificates" value={this.state.numberOfCertificates} onChange={this.handleChange}  />
                    </div>
                    <div className="col-xs-12 col-sm-12">
                        &nbsp;
                    </div>

                    <div className="col-xs-12 col-sm-6">
                        <button className="btn btn-primary" disabled={this.state.isProcessing} onClick={this.previousStep} style={{width: "100%"}}>Previous Page</button><br />
                        <br />
                    </div>
                    <div className="col-xs-12 col-sm-6">
                        <button className="btn btn-primary" disabled={!this.isFormValid() || this.state.isProcessing} onClick={this.makePaymentByCreditCard} style={{width: "100%"}}>Pay now by Credit Card</button><br />
                        <br />
                    </div>
                </div>
            </div>
        );
    }
}
/*
                <div className="col-xs-12 col-sm-4">
                    <button className="btn btn-primary" disabled={!this.isFormValid() || this.state.isProcessing} onClick={this.makePaymentByCheck} style={{width: "100%"}}>Mail a Check</button><br />
                    <br />
                </div>

*/

export default Step3;