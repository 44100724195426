import React, { Component } from 'react';
import {HashRouter as Router, Switch, Route } from 'react-router-dom';
import './App.css';
import Step1Grammy from './components/Step1Grammy';
import Step1LatinGrammy from './components/Step1LatinGrammy';
import Step2 from './components/Step2';
import Step3 from './components/Step3';
import CreditCardPayment from './components/CreditCardPayment';
import ThankYou from './components/ThankYou';
import config from './config';
//import { Redirect } from 'react-router-dom';
//import Closed from './components/Closed'

let Step1;

if (config.environment.application.academyID === 2) {
  Step1 = Step1LatinGrammy;
} else {
  Step1 = Step1Grammy;
}

class App extends Component {
    constructor(props) {
      super(props);

      this.state = {
          currentStep: 1,
          awardYears: [],
          selectedAwardYear: "",
          categories: [],
          selectedCategoryNumber: "",
          historyItems: [],
          selectedHistoryItem: "",
          certificate: { 
            id: 0, 
            name: "",
            email: "",
            phone: "",
            address1: "",
            address2: "",
            address3: "",
            addressUnitNumber: "",
            city: "",
            stateProvince: "",
            postalCode: "",
            country: "",
            numberOfCertificates: 0,
            totalCost: 0,
            participationDescription: "",
            participationNotes: "",
            historyID: 0,
            selectedFile: null,
            nameOnCertificate: "",
            certificateHash: ""
          },
          academyID: config.environment.application.academyID,
          paymentMethod: "",
          transactionID: ""
      };
  }
/*
  render() {
    return (
      <Router>
        <Switch>
          <Route path="/" exact component={Closed} />
          <Route path="/step1" component={Closed} />
          <Route path="/step2" render={() => <Closed parentState={this.state} />} />
          <Route path="/step3" render={() => <Closed parentState={this.state} />} />
          <Route path="/payment" render={() => <Closed parentState={this.state} />} />
          <Route path="/thankyou" render={() => <Closed parentState={this.state} />} />
          <Route path="/closed" render={() => <Closed parentState={this.state} />} />
        </Switch>
      </Router>
    )
  }
*/
  
  //This is the router you use while in-season.
  render() {
    return (
      <Router>
        <Switch>
          <Route path="/" exact component={Step1} />
          <Route path="/step1" component={Step1} />
          <Route path="/step2" render={() => <Step2 parentState={this.state} />} />
          <Route path="/step3" render={() => <Step3 parentState={this.state} />} />
          <Route path="/payment" render={() => <CreditCardPayment parentState={this.state} />} />
          <Route path="/thankyou" render={() => <ThankYou parentState={this.state} />} />
        </Switch>
      </Router>
    )
  }
}

export default App;
