import React, { Component } from 'react';
import { Redirect } from 'react-router-dom';
// import awardYearsJson from '../data/award_years_full.json';
import config from '../config';
import ProcessingOverlay from './ProcessingOverlay';

class Step2 extends Component {

    constructor(props) {
        super(props);
        
        this.state = {
            awardYears: this.props.parentState.awardYears,
            selectedAwardYear: this.props.parentState.selectedAwardYear,
            categories: this.props.parentState.categories,
            selectedCategoryNumber: this.props.parentState.selectedCategoryNumber,
            historyItems: this.props.parentState.historyItems,
            selectedHistoryItem: this.props.parentState.selectedHistoryItem,
            isProcessing: false,
            previousStep: false,
            nextStep: false
        }

        console.log(config.environment.application);
    }
    
    updateParentState() {
        this.props.parentState.awardYears = this.state.awardYears;
        this.props.parentState.selectedAwardYear = this.state.selectedAwardYear;
        this.props.parentState.categories = this.state.categories;
        this.props.parentState.selectedCategoryNumber = this.state.selectedCategoryNumber;
        this.props.parentState.historyItems =  this.state.historyItems;
        this.props.parentState.selectedHistoryItem = this.state.selectedHistoryItem;
        this.props.parentState.certificate.historyID = this.state.selectedHistoryItem.historyID;
    }

    nextStep = (e) => {
        e.preventDefault();
        this.updateParentState();
        this.setState({nextStep: true});
    }

    previousStep = (e) => {
        e.preventDefault();
        this.updateParentState();
        this.setState({previousStep: true});
    }

    selectAwardYearOnChange(event) {
        //Show a validation error.
        this.setState({selectedAwardYear: event.target.value, validationError: event.target.value === "" ? "Select Show of Winning/Nominated Project" : ""}); 

        //Reset all the values so that the lower display items disappear.
        this.setState({
            categories: [],
            selectedCategoryNumber: "",
            historyItems: []
        });

        //Load new data.
        this.loadHistoryDataForSelectedAwardYear(event.target.value);
    }

    loadAwardYears() {
        this.setState({isProcessing: true});

        let url = config.environment.application.baseUrl + "/api/awardyears/" ;

        fetch(url, {
            method: 'GET',
            headers: new Headers({
                'Content-Type': 'application/json'
            })
        })
        .then((response) => {
            return response.json();
        })
        .then((data) => {
            let years = data.map(year => {
                return {
                    showNumber: year.showNumber,
                    year: year.year,
                    name: year.name
                };
            });

            this.setState({ awardYears: years });
        })
        .then((data) => {
            this.setState({isProcessing: false});
        })
        .catch((error) => {
            console.log(error);
            this.setState({isProcessing: false});
        });
    }

    componentDidMount() {
        // let award_years_from_json = awardYearsJson.map(award_year => { return {value: award_year.showNumber, display: award_year.name + " (" + award_year.year + ")"} });
        this.loadAwardYears();
        // this.setState({ awardYears: [{value: '', display: 'Please select year...'}].concat(award_years_from_json) });
    }

    loadCategories() {
        let last_category_number = 0;
        let mapped_category_names = [];

        mapped_category_names.push({ categoryNumber: "", categoryName: "Please select category..." })
        
        this.state.historyItems.forEach(history_item => {
            if (last_category_number !== history_item.categoryNumber) {
                last_category_number = history_item.categoryNumber;
                mapped_category_names.push({ categoryNumber: history_item.categoryNumber, categoryName: history_item.categoryName })
            }
        });

        this.setState({ categories: mapped_category_names });
    }

    categoryNameAndNumber(category_item) {
        if (category_item.categoryNumber !== "")
            return category_item.categoryNumber + ". " + category_item.categoryName;
        else
            return category_item.categoryName;
    }

    loadHistoryDataForSelectedAwardYear(new_value) {
        if (new_value === "")
            return;

        //Set the processing flag here, but don't set it to false until either the final .then() statement or an error because of the asynchronousness of this.
        this.setState({isProcessing: true});

        let url = config.environment.application.baseUrl + "/api/history/" + new_value;

        fetch(url, {
            method: 'GET',
            headers: new Headers({
                'Content-Type': 'application/json'
            })
        })
        .then((response) => {
            return response.json();
        })
        .then((data) => {
            let history_items = data.map(history_item => {
                //It is being mapped in this way so no one can steal the data we sell to Jaxta (the display lines)
                return {
                    historyID: history_item.id,
                    categoryNumber: history_item.attributes.category_number,
                    categoryName: history_item.attributes.category_name,
                    isWinner: history_item.attributes.is_winner, 
                    displayLine1: history_item.attributes.title,
                    displayLine2: history_item.attributes.artist_name 
                };
            });

            this.setState({ historyItems: history_items });
        })
        .then((data) => {
            this.loadCategories();
            this.setState({isProcessing: false});
        })
        .catch((error) => {
            console.log(error);
            this.setState({isProcessing: false});
        });
    }

    selectHistoryItem(selected_history) {
        this.setState({selectedHistoryItem: selected_history});
    }

    isSelectedHistoryItemCurrentlyDisplayed() {
        //This function is used for enabling "Next" button.  They can only move forward if they have selected a history record and that record is currently displayed.
        if (this.state.selectedHistoryItem === "")
            return false;

        if (this.state.selectedHistoryItem.categoryNumber.toString() === this.state.selectedCategoryNumber.toString())
            return this.state.historyItems.includes(this.state.selectedHistoryItem);
        
        return false;
    }

    renderCategoryNumberSelect() {
        if (this.state.selectedAwardYear && this.state.historyItems.length !== 0) {
            return (
                <select className={"form-control " + (this.state.selectedCategoryNumber === "" ? "validationError" : "")} value={this.state.selectedCategoryNumber}
                    onChange= {
                        (e) => { 
                            this.setState({selectedCategoryNumber: e.target.value}); 
                        }
                    }
                >
                    {this.state.categories.map((category_item) => <option key={category_item.categoryNumber} value={category_item.categoryNumber}>{this.categoryNameAndNumber(category_item)}</option>)}
                </select>
            );
        }
        else if (this.state.selectedAwardYear) {
            return (
                <h5 style={{marginTop: "8px"}}>Loading categories...</h5>
            );
        }
    }

    renderHistoryItems() {
        if (this.state.historyItems === null)
            return "";
        
        return this.state.historyItems.map((item) => {
            if (item.categoryNumber.toString() === this.state.selectedCategoryNumber) {
                return (
                    <div key={item.historyID}>  
                        <div className={"row " + ((this.state.selectedHistoryItem && this.state.selectedHistoryItem.historyID === item.historyID) ? " selectedHistoryItem" : "")} style={{margin: "10px"}}>
                            <div className="col-xs-12 col-sm-3" style={{minHeight: "35px"}}>
                                {
                                    (this.state.selectedHistoryItem && this.state.selectedHistoryItem.historyID === item.historyID) ? null : 
                                        <button type="button" onClick={(event) => {this.selectHistoryItem(item)}} className="btn btn-outline-primary" style={{width: "100%"}}>Select</button>
                                }
                            </div>
                            <div className="col-xs-12 col-sm-9">
                                {item.displayLine1} <b>{item.isWinner ? "WINNER" : ""}</b>{item.displayLine2 != null ? <br /> : null}
                                {item.displayLine2}{item.displayLine3 != null ? <br /> : null}
                                {item.displayLine3}<br />
                            </div>
                        </div>
                    </div>
                );
            }
            else 
                return "";
        });
    }

    render() {
        if (this.state.nextStep)
            return (<Redirect push to='/step3' />);

        if (this.state.previousStep)
            return (<Redirect push to='/' />);

        return (
            <div>
                <ProcessingOverlay isProcessing={this.state.isProcessing} />  
                <br />
                <h5>Please select the GRAMMY<sup>&reg;</sup> Nominated or Winning project you worked on.</h5>
                <div><b>NOTE:</b> Search in adjacent years if you can't find the record/artist.</div>

                <select value={this.state.selectedAwardYear} onChange={ (event) => {this.selectAwardYearOnChange(event);}} className={"form-control " + (this.state.selectedAwardYear === "" ? "validationError" : "")}>
                    <option value="">Please select year...</option>
                    {this.state.awardYears.map((award_year) => <option key={award_year.showNumber} value={award_year.showNumber}>{award_year.name}</option>)}
                </select>
                {this.renderCategoryNumberSelect()}
                <br />
                {this.renderHistoryItems()}
                <br />
                <div className="row">
                    <div className="col-xs-12 col-sm-6 col-md-6">
                        <input type="button" className="btn btn-primary" style={{width: "100%"}} onClick={this.previousStep} disabled={this.state.isProcessing} value="Previous Page" /><br />
                        <br />
                    </div>

                    <div className="col-xs-12 col-sm-6 col-md-6">
                        <input type="button" className="btn btn-primary" style={{width: "100%"}} onClick={this.nextStep} disabled={!this.isSelectedHistoryItemCurrentlyDisplayed() || this.state.isProcessing} value="Next Page" /><br />
                        <br />
                    </div>
                </div>
            </div>
        );
    }
}

export default Step2;